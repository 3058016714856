import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Home from "./pages/home";
// import Omnifood from "./pages/omnifood";
import UnderConstruction from "./pages/underConstruction";
import Cpsc581 from "./pages/cpsc581";
import ATaleOfTwoButtons from "./pages/aTaleOfTwoButtons";
import PhoneSensorUnlock from "./pages/phoneSensorUnlock";
import Proxemics from "./pages/proxemics";
import Buffify from "./pages/buffify";
import PhysicalPrototype from "./pages/physicalPrototype";
import PageNotFound from "./pages/pageNotFound";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/used" element={<Used />} /> */}

        {/* <Route path="/home" element={<Home />} /> */}
        {/* <Route path="/omnifood" element={<Omnifood />} /> */}
        <Route path="/" element={<UnderConstruction />} />
        <Route path="/human-computer-interactions" element={<Cpsc581 />} />
        <Route path="/a-tale-of-two-buttons" element={<ATaleOfTwoButtons />} />
        <Route path="/phone-sensor-unlock" element={<PhoneSensorUnlock />} />
        <Route path="/proxemics" element={<Proxemics />} />
        <Route path="/physical-prototype" element={<PhysicalPrototype />} />
        <Route path="/live-demo-buffify" element={<Buffify />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
