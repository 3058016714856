import React, { useState } from "react";
import { useEffect } from "react";

import "../components/underConstruction.css";

import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import videoDemo from "../assets/hci/indivProject/video/smartBathroomMirror.mp4";
import projectThreeImg from "../assets/hci/indivProject/img/MirrorBackground.webp";

// Initial Sketches:
import onlinePoker from "../assets/hci/indivProject/initialSketches/onlinePoker.png";
import smartBeverageMachine from "../assets/hci/indivProject/initialSketches/smartBeverageMachine.png";
import smartBookcase from "../assets/hci/indivProject/initialSketches/smartBookcase.png";
import smartCatEnclosure from "../assets/hci/indivProject/initialSketches/smartCatEnclosure.png";
import smartCatInteractor from "../assets/hci/indivProject/initialSketches/smartCatInteractor.png";
import smartFireplace from "../assets/hci/indivProject/initialSketches/smartFireplace.png";
import smartFoldableBed from "../assets/hci/indivProject/initialSketches/smartFoldableBed.png";
import smartShowerMirror from "../assets/hci/indivProject/initialSketches/smartShowerMirror.png";
import smartTile from "../assets/hci/indivProject/initialSketches/smartTile.png";
import smartWarmingCoolingTable from "../assets/hci/indivProject/initialSketches/smartWarmingCoolingTable.png";

// Refined Sketches:
import approachSmartMirrorStory from "../assets/hci/indivProject/refinedSketches/approachSmartMirrorStory.png";
import facialRecognitionMotivationalQuoteStory from "../assets/hci/indivProject/refinedSketches/facialRecognitionMotivationalQuoteStory.png";
import identifyMultiplePeopleStory from "../assets/hci/indivProject/refinedSketches/identifyMultiplePeople.png";

const Proxemics = () => {
  // Modify Background to be unique to HCI Project Pages
  useEffect(() => {
    document.body.className = "group-project4 wrapper";
    return () => {
      document.body.className = ""; // Reset on unmount
    };
  }, []);

  // State for enlarged image
  const [enlargedImage, setEnlargedImage] = useState(null);

  // Array of images with captions
  const initialSketches = [
    {
      src: onlinePoker,
      alt: "onlinePoker",
      caption:
        "|Figure 1: Online Poker + Movement Tracker| This concept sketch is use a camera to track the users movement of specialized cards and chips. Instead of clicking on a screen to play poker, the user gestures with cards and chips to play online poker.",
    },
    {
      src: smartFireplace,
      alt: "smartFireplace",
      caption:
        "|Figure 2: Smart Fireplace + Distance Tracking| This concept sketch uses distance tracking to turn on or off a fireplace. If the user is within a set range, the fireplace with burn. If the user is far away the fireplace will turn off.",
    },
    {
      src: smartTile,
      alt: "smartTile",
      caption:
        "|Figure 3: Smart Tile + Identification| When the user steps on a tile, the tile and surrounding tiles either heat up or cool down depending on the season.",
    },
    {
      src: smartShowerMirror,
      alt: "smartShowerMirror",
      caption:
        "|Figure 4: Smart Shower Mirror + Movement Tracking| The original idea was for there to be a steamy mirror and with the wave of your hand the mirror is cleaned of the steam. Then based on facial expressions, the mirror supples quotes.",
    },
    {
      src: smartBeverageMachine,
      alt: "smartBeverageMachine",
      caption:
        "|Figure 5: Smart Beverage Machine + Identification| The idea is that the Beverage Machine can detect the type of cup and produce the beverage that you want. For example: Mug = Coffee, Tall Glass Cup = Pop, Teacup = Tea, etc",
    },
    {
      src: smartFoldableBed,
      alt: "smartFoldableBed",
      caption:
        "|Figure 6: Smart Foldable Bed + Orientation and Distance| The idea is to track the orientation and distance of the user to the bed. If the user is close enough to the bed and is facing the bed then it will fold down. If the user is close but looking away, the bed will not fold out.",
    },
    {
      src: smartCatInteractor,
      alt: "smartCatInteractor",
      caption:
        "|Figure 7: Smart Cat Interactor + Distance and Orientation| The idea is for a cat to bring a spring to a machine and drop off the spring. When the cat looks away, the machine will lanuch the spring into the air for the cat to chase and bring back.",
    },
    {
      src: smartCatEnclosure,
      alt: "smartCatEnclosure",
      caption:
        "|Figure 8: Smart Cat Enclosure + Distance and Orientation| The idea is for a cat to walk towards the door of a outdoor cat enclosure and when in range, the enclosure door will open for the cat. Once inside, if the cat looks towards the bird feeder it will release seeds for bird watching.",
    },
    {
      src: smartBookcase,
      alt: "smartBookcase",
      caption:
        "|Figure 9: Smart Bookcase + Distance and Orientation| The idea is that the user would choose a book and sit down close by. If the user looks at the bookcase, it turns into a giant kindle.",
    },
    {
      src: smartWarmingCoolingTable,
      alt: "smartWarmingCoolingTable",
      caption:
        "|Figure 10: Smart Warming Cooling Table + Detection| The idea is that when someone places something on the smart table, the table would distinguish if the object needs to maintain heat or cool down. For example, keeping food hot and cooling your laptop.",
    },
  ];

  const refinedSketches = [
    {
      src: approachSmartMirrorStory,
      alt: "approachSmartMirrorStory",
      caption:
        "|Figure: Approach Smart Mirror Storyboard | This storyboard shows a user approaching a smart mirror. When the user is outside the mirrors display range, the mirror is just a normal mirror. Once the user is within the display range, the mirror will display some daily information like the weather, location, time, and date. When the user looks away or moves out of range the mirror's display turns off.",
    },
    {
      src: facialRecognitionMotivationalQuoteStory,
      alt: "facialRecognitionMotivationalQuoteStory",
      caption:
        "|Figure: Facial Recognition Motivational Quote Storyboard | This storyboard shows a user's facial expression. Depending on the facial expression of the user, the mirror would give a different set of motivational quotes. If the person has a happy expression, the quotes are focused around keeping/maintain the positive energy. If the person has a sad expression, the quote is more motivational to not give up.",
    },
    {
      src: identifyMultiplePeopleStory,
      alt: "identifyMultiplePeopleStory",
      caption:
        "|Figure: Identify Multiple People Storyboard | This storyboard shows how the mirror reacts when there are more than one user. If there are more than one user in range, the mirror greets each user.",
    },
  ];

  // State for initial sketches carousel
  const [currentInitialIndex, setCurrentInitialIndex] = useState(0);

  // State for refined sketches carousel
  const [currentRefinedIndex, setCurrentRefinedIndex] = useState(0);

  const nextInitial = () => {
    setCurrentInitialIndex((currentInitialIndex + 1) % initialSketches.length);
  };

  const prevInitial = () => {
    setCurrentInitialIndex(
      (currentInitialIndex - 1 + initialSketches.length) %
        initialSketches.length
    );
  };

  const nextRefined = () => {
    setCurrentRefinedIndex((currentRefinedIndex + 1) % refinedSketches.length);
  };

  const prevRefined = () => {
    setCurrentRefinedIndex(
      (currentRefinedIndex - 1 + refinedSketches.length) %
        refinedSketches.length
    );
  };

  return (
    <div className="container">
      {/* Enlarge Image Overlay */}
      {enlargedImage && (
        <div
          className="enlarged-image-overlay"
          onClick={() => setEnlargedImage(null)}
        >
          <img src={enlargedImage} alt="Enlarged" className="enlarged-image" />
        </div>
      )}

      <div className="section-header hci-group-project4">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-center">Proxemics - Smart Bathroom Mirror</div>
          <div className="header-links">
            <a
              className="header-nav-option"
              href="/human-computer-interactions"
            >
              HCI Projects
            </a>
          </div>
        </div>
      </div>

      <div className="section-project group-project4">
        {/* Project Hero */}
        <div className="project-hero">
          <div className="project-header">
            <div className="project-title">Smart Bathroom Mirror</div>
            {/* <div className="project-subtitle">Group Project 1</div> */}
            <div className="groupmates">Alan Huynh</div>
          </div>
          <div className="project-hero-img-container">
            <img
              className="project-hero-img"
              src={projectThreeImg}
              alt={"Bathroom Wall"}
            />
          </div>
          <div className="project-overview">
            <div className="project-overview-title">
              Project Overview: "Individual Project - Proxemics"
            </div>
            <p className="sketch-decription">
              The goal of this project was to design and implement an
              innovative, proxemic-based interface for a home applicaition to
              make it smart. The interface needs to have a unique, interesting,
              or challenging scenario/problem.
            </p>
            <p className="sketch-decription">
              For my project, titled <i>Smart Bathroom Mirror</i>, was designed
              around keeping yourself motivated. The idea is that one of the
              first things you do in the morning and one of the last things you
              do at night is brush your teeth infront of your bathroom mirror.
              The bathroom mirror is a great source to motivate you to start
              your day and to keep going at the end of the night. My goal is to
              make a smart mirror with proxemics to motivate you to keep on
              keeping on.
            </p>
            <p className="sketch-description">
              This portfolio will document the design process, initial sketches,
              storyboard, final design, and final demonstrations.
            </p>
          </div>
        </div>

        {/* Project Initial Sktches */}
        <div className="project-initialSketch-container">
          <div className="left-col">
            <div className="project-initialSketch-title">
              Initial Sketches: Design Process for Proxemics Applications
            </div>
            <p className="sketch-decription">
              From my initial sketches, I had some interesting ideas. The
              sketches that I was most interested in was tracking user movement
              while they play online poker (Sketch 1), a smart cat toy
              interactor that throws springs when a cat gets close (Sketch 7),
              and a smart bathroom mirror that shares quotes (Sketch 4).
            </p>
            <p className="sketch-decription">
              For more details about the sketches, please view the carousel on
              the right, which showcases all 10 initial designs.
            </p>
          </div>
          <div className="right-col">
            <div className="carousel">
              <img
                className="initial-sketch"
                src={initialSketches[currentInitialIndex].src}
                alt={initialSketches[currentInitialIndex].alt}
                onClick={() =>
                  setEnlargedImage(initialSketches[currentInitialIndex].src)
                }
              />
              <p className="caption">
                {initialSketches[currentInitialIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project4 prev"
                  onClick={prevInitial}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project4 next"
                  onClick={nextInitial}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Project Refine Sketch */}
        <div className="project-refinedSketch-container">
          <div className="left-col">
            <div className="carousel">
              <img
                className="refined-sketch"
                src={refinedSketches[currentRefinedIndex].src}
                alt={refinedSketches[currentRefinedIndex].alt}
                onClick={() =>
                  setEnlargedImage(refinedSketches[currentRefinedIndex].src)
                }
              />
              <p className="caption">
                {refinedSketches[currentRefinedIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project4 prev"
                  onClick={prevRefined}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project4 next"
                  onClick={nextRefined}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="right-col">
            <div className="project-refinedSketch-title">
              Storyboard - Smart Bathroom Mirror
            </div>
            <p className="sketch-decription">
              After reviewing my 10 initial sketches, I chose the Smart Bathroom
              Mirror sketch. The reason I chose to refine and create an
              application for a smart bathroom mirror was that I thought it was
              a unique idea that others probably did not think of and it was a
              fun idea that I would want in my own home.
            </p>
            <p className="sketch-decription">
              To refine my initial smart bathroom mirror concept, I decided on a
              few different proxemic interactions. The first interaction was the
              distance the user was away from the mirror, if too far the mirror
              acted just like a normal mirror but when the user was close enough
              the mirror would display some daily information to the user along
              with a motivating quote. The second interaction was to be able to
              recognize a set of facial expressions and set quotes accordingly.
              Lastly, the final interaction I wanted to include was to be able
              to recognize more than one object.
            </p>
            <p className="sketch-decription">
              For more details about the storyboards, please view the carousel
              on the left, which showcases the three main designs.
            </p>
          </div>
        </div>

        {/* Project Final Product */}
        <div className="project-finalProduct-container">
          <div className="project-finalProduct-title">
            Final Design: Smart Bathroom Mirror Application
          </div>
          <div className="project-video">
            <video controls class="video-demo" src={videoDemo} />
          </div>
          <p className="sketch-decription">
            For the final design of the project, markers are used to represent
            users and facial expressions.
          </p>
          <p className="sketch-decription">
            The mirror appears normal when outside the display range of the
            mirror. When the user is within range, the mirror will display
            information. When the user is not orientated towards the mirror or
            when the user is too far away, the mirror will not display
            information.
          </p>
          <p className="sketch-decription">
            Based on the user's facial expression (in demo case, markers), the
            mirror will produce a different quote. In addition, the mirror is
            capable of viewing more than one user.
          </p>
          <div className="project-demo-link">
            <button className="project-btn">
              <a
                className="project-link"
                href="https://alans-individualproject.glitch.me/"
              >
                Smart Bathroom Mirror Demo
              </a>
            </button>
          </div>
        </div>

        <div className="project-contribution-container">
          <div className="left-col">
            <div className="project-contribution-title">Contribution:</div>
            <p className="sketch-decription">
              <ul className="contribution-list">
                <li className="contribution-bullet">
                  Project Created by Alan Huynh
                </li>
              </ul>
            </p>
          </div>
          <div className="right-col">
            <div className="project-contribution-title">
              Source Code & References:
            </div>
            <p className="sketch-decription">
              <ul className="contribution-list references">
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="https://glitch.com/edit/#!/alans-individualproject"
                  >
                    Source Code
                  </a>
                </li>
                <li className="contribution-bullet">
                  Refernces:
                  <ul>
                    <li>
                      <a
                        className="anchor-hci-group-project3"
                        href="https://github.com/damianofalcioni/js-aruco2"
                      >
                        Initial Template Code
                      </a>{" "}
                      Provided by Damiano Falcioni and Juan Mellado
                    </li>
                    <li>Backgroung Images: Generated on Dall-e by ChatGPT</li>
                  </ul>
                </li>
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="/human-computer-interactions"
                  >
                    HCI Portfolio
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* <div className="section-footer-project">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Proxemics;
