import React, { useState } from "react";
import { useEffect } from "react";

// CSS
import "../components/underConstruction.css";

// Header Logo
import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import projectThreeImg from "../assets/hci/groupProject3/img/screenWise.webp";

// Initial Sketches:
import catStopper from "../assets/hci/groupProject3/initialSketches/catStopper.png";
import portableSecurityAlarm from "../assets/hci/groupProject3/initialSketches/portableSecurityAlarm.png";
import sunlightAlarm from "../assets/hci/groupProject3/initialSketches/sunlightAlarm.png";
import dayAndNightFeeder from "../assets/hci/groupProject3/initialSketches/dayAndNightFeeder.png";
import proximityTaskEnforcer from "../assets/hci/groupProject3/initialSketches/proximityTaskEnforcer.png";
import returnItemReminder from "../assets/hci/groupProject3/initialSketches/returnItemReminder.png";
import smartToiletSeat from "../assets/hci/groupProject3/initialSketches/smartToiletSeat.png";
import digitalTapeMeasure from "../assets/hci/groupProject3/initialSketches/digitalTapeMeasure.png";
import openDoorReminder from "../assets/hci/groupProject3/initialSketches/openDoorReminder.png";
import customDiceRoller from "../assets/hci/groupProject3/initialSketches/customDiceRoller.png";

// Storyboard Sketches
import optimalView from "../assets/hci/groupProject3/refinedSketches/optimalView.png";
import perfectTV from "../assets/hci/groupProject3/refinedSketches/perfectTV.png";

const PhysicalPrototype = () => {
  // Modify Background to be unique to HCI Project Pages
  useEffect(() => {
    document.body.className = "group-project3 wrapper";
    return () => {
      document.body.className = ""; // Reset on unmount
    };
  }, []);

  // Array of images with captions
  const initialSketches = [
    {
      src: catStopper,
      alt: "Cat Stopper Sketch",
      caption:
        "Cat Stopper Sketch - Ultrasonic Sensor + Active Buzzer| The concept for this sketch is to scare off cats from going into areas that they are not suppose to. The ultrasonic sensor is set at the height level of the cat and once within range the active buzzer would trigger to scare the cat away. The sketch shows a scenario of a door, but this concept could be used for other situations such as countertops.",
    },
    {
      src: portableSecurityAlarm,
      alt: "Portable Security Alarm Sketch",
      caption:
        "Portable Security Alarm Sketch - Orientation Sensor + Active Buzzer| The concept for this sketch is to scare off thieves. The sketch illustrates a scenario of the alarm system being placed in a bad and when someone moves the bag the orientation sensor would detect the movement and the active buzzer would trigger.",
    },
    {
      src: sunlightAlarm,
      alt: "Sunlight Alarm Sketch",
      caption:
        "Sunlight Alarm Sketch - Photoresistor + Active Buzzer| The concept for this sketch is to get the user to wake up to sunlight. The user would set the alarm with a button to trigger sleep mode and ensure that no light is detected. In the morning, the photoresistor would detect first light and the active buzzer would trigger, waking up the user.",
    },
    {
      src: dayAndNightFeeder,
      alt: "Day And Night Feeder Sketch",
      caption:
        "Day And Night Feeder Sketch - Photoresistor + Motor| The concept for this sketch is to have an autofeeder that auto feeds based on sunlight. The idea is that at first light, the feed would dispense food and once light is no longer sense the feed would dispense again.",
    },
    {
      src: proximityTaskEnforcer,
      alt: "Proximity Task Enforcer Sketch",
      caption:
        "Proximity Task Enforcer Sketch - Joystick + LCD + Ultrasonic Sensor + Active Buzzer| The concept for this sketch is to keep the user on task. The idea is that the user would use the joystick to set a timer to work on a task and the ultrasonic sensor to ensure that the user is still within range. If the user were to move out of range and do something else the active buzzer would trigger to indicate to the user to get back on task.",
    },
    {
      src: returnItemReminder,
      alt: "Return Item Reminder Sketch",
      caption:
        "Return Item Reminder Sketch - Ultrasonic or Pressure Sensor + Active Buzzer | The concept for this sketch is to remind the user that they need to return an item back to its original place. The idea is that the ultrasonic or pressure sensor would recognize or detect when an item is removed from its origin and if it is not returned in a set amount of time then the active buzzer would trigger until the item is returned.",
    },
    {
      src: smartToiletSeat,
      alt: "Smart Toilet Seat Sketch",
      caption:
        "Smart Toilet Seat Sketch - Ultrasonic Sensor + Motor| The concept for this sketch is to help users never have to have the toilet seat lid debate ever again. The idea is that the smart toilet seat lid would be able to detect if someone plans to sit on the toilet seat or not and move the lid accordingly.",
    },
    {
      src: digitalTapeMeasure,
      alt: "Digital Tape Measure Sketch",
      caption:
        "Digital Tape Measure Sketch - LCD + Ultrasonic Sensor| The concept for this sketch is to allow the user to digitally measure distances. The idea is to use the ultrasonic sensor to measure distance.",
    },
    {
      src: openDoorReminder,
      alt: "Open Door Reminder Sketch",
      caption:
        "Open Door Reminder Sketch - Ultrasonic Sensor + Active Buzzer| The concept for this sketch is to remind the user if they left the door open. The idea is to use the ultrasonic sensor to detect if the door is open. If the door is open for long than a set time frame then the active buzzer will ring until the door is closed.",
    },
    {
      src: customDiceRoller,
      alt: "Custom Dice Roller Sketch",
      caption:
        "Custom Dice Roller Sketch - Joystick + LCD + Orientation Sensor| The concept for this sketch is to allow the user to roll any type of dice without carrying a bag for die. The idea is to use the joystick to set the type of dice. Once set, the user shakes the device and the orientation sensor would detect the movement and 'roll' the dice to a random number.",
    },
  ];

  const refinedSketches = [
    {
      src: optimalView,
      alt: "Optimal View Storyboard",
      caption:
        "Optimal View Storyboard | The sketch explains the scenario where the user is trying to determine the optimal viewing distance they should sit away from the TV.",
    },
    {
      src: perfectTV,
      alt: "Perfect TV Storyboard",
      caption:
        "Perfect TV Storyboard | The sketch explains the scenario where the user does not own a TV yet but has their entertainment area setup and needs to decide on what size of TV to get.",
    },
  ];

  // State for initial sketches carousel
  const [currentInitialIndex, setCurrentInitialIndex] = useState(0);

  // State for refined sketches carousel
  const [currentRefinedIndex, setCurrentRefinedIndex] = useState(0);

  // State for enlarged image
  const [enlargedImage, setEnlargedImage] = useState(null);

  const nextInitial = () => {
    setCurrentInitialIndex((currentInitialIndex + 1) % initialSketches.length);
  };

  const prevInitial = () => {
    setCurrentInitialIndex(
      (currentInitialIndex - 1 + initialSketches.length) %
        initialSketches.length
    );
  };

  const nextRefined = () => {
    setCurrentRefinedIndex((currentRefinedIndex + 1) % refinedSketches.length);
  };

  const prevRefined = () => {
    setCurrentRefinedIndex(
      (currentRefinedIndex - 1 + refinedSketches.length) %
        refinedSketches.length
    );
  };

  return (
    <div className="container">
      {/* Enlarge Image Overlay */}
      {enlargedImage && (
        <div
          className="enlarged-image-overlay"
          onClick={() => setEnlargedImage(null)}
        >
          <img src={enlargedImage} alt="Enlarged" className="enlarged-image" />
        </div>
      )}

      <div className="section-header hci-group-project3">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-center">Physical Prototype - ScreenWise</div>
          <div className="header-links">
            <a
              className="header-nav-option"
              href="/human-computer-interactions"
            >
              HCI Projects
            </a>
          </div>
        </div>
      </div>

      <div className="section-project group-project3">
        {/* Project Hero */}
        <div className="project-hero">
          <div className="project-header">
            <div className="project-title">ScreenWise</div>
            {/* <div className="project-subtitle">Group Project 1</div> */}
            <div className="groupmates">
              Alan Huynh, Braden Beler, Desmond Lau
            </div>
          </div>
          <div className="project-hero-img-container">
            <img
              className="project-hero-img"
              src={projectThreeImg}
              alt={"ScreenWise"}
            />
          </div>
          <div className="project-overview">
            <div className="project-overview-title">
              Project Overview: "Group Project 3 - Physical Prototype"
            </div>
            <p className="sketch-decription">
              The goal of this project was to design a physical prototype that
              addresses a common everyday problem. As a group, we identified the
              challenge of determining the optimal viewing distance for TVs and
              decided to create a solution. Our device, which we named{" "}
              <i>ScreenWise</i>, provides a simple and effective way to ensure
              an ideal viewing experience.
            </p>
            <p className="sketch-decription">
              Choosing the right TV size is only part of the equation, knowing
              the proper viewing distance is equally important! Sitting too
              close can cause unnecessary eye strain, while sitting too far can
              diminish the immersive experience of your screen.{" "}
              <i>ScreenWise</i> eliminates the guesswork by using an ultrasonic
              sensor to measure the distance from the viewer to the TV and
              providing real-time feedback through an LCD display and LED
              lights. This ensures you can always enjoy your TV with comfort and
              clarity. <i>ScreenWise</i> is your everyday solution to a better,
              healthier viewing experience!
            </p>
          </div>
        </div>

        {/* Project Initial Sktches */}
        <div className="project-initialSketch-container">
          <div className="left-col">
            <div className="project-initialSketch-title">
              Initial Sketches: Design Process for ScreenWise
            </div>
            <p className="sketch-decription">
              For this project, we worked with an Arduino kit equipped with a
              variety of input and output sensors. The project constraints
              required us to use at least one sensor-based input, one physical
              output, and to conceal the wiring as effectively as possible. With
              these requirements in mind, our group collectively reviewed the
              assortment of Arduino components available, and each member
              produced 10 initial sketches.
            </p>
            <p className="sketch-decription">
              Reflecting on my sketches, I noticed a preference for the
              ultrasonic sensor when addressing the sensor-based input
              requirement. The majority of my initial designs (sketches 1, 5, 6,
              7, 8, and 9) incorporated the ultrasonic sensor. Additionally, I
              explored concepts using the orientation sensor (sketches 2 and 10)
              and the photoresistor (sketches 3 and 4), although these were less
              prominent in my designs.
            </p>
            <p className="sketch-decription">
              For the physical output component, I gravitated toward the active
              buzzer, which featured in many of my concepts (sketches 1-3, 5-6,
              and 9). I also experimented with designs utilizing the LCD display
              and motor components (sketches 4, 7, 8, and 10). Moving forward, I
              believe brainstorming the use of other Arduino components could
              lead to even more innovative and creative solutions.
            </p>
            <p className="sketch-decription">
              Among my initial sketches, the concept I enjoyed the most was the
              Smart Toilet Seat. This idea aimed to address the timeless debate
              about whether the toilet seat should be left up or down, offering
              a practical and creative solution to a common household issue.
              However, the challenge of implementing the logic for when to raise
              or lower the seat proved to be a significant hurdle. Given more
              time, I would have loved to further develop this concept.
            </p>
            <p className="sketch-decription">
              For a closer look at the initial sketches, please view the
              carousel on the right, which showcases all 10 design concepts in
              detail.
            </p>
          </div>
          <div className="right-col">
            <div className="carousel">
              <img
                className="initial-sketch"
                src={initialSketches[currentInitialIndex].src}
                alt={initialSketches[currentInitialIndex].alt}
                onClick={() =>
                  setEnlargedImage(initialSketches[currentInitialIndex].src)
                }
              />
              <p className="caption group-project3">
                {initialSketches[currentInitialIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project3 prev"
                  onClick={prevInitial}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project3 next"
                  onClick={nextInitial}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Project Refine Sketch */}
        <div className="project-refinedSketch-container">
          <div className="left-col">
            <div className="carousel">
              <img
                className="refined-sketch"
                src={refinedSketches[currentRefinedIndex].src}
                alt={refinedSketches[currentRefinedIndex].alt}
                onClick={() =>
                  setEnlargedImage(refinedSketches[currentRefinedIndex].src)
                }
              />
              <p className="caption group-project3">
                {refinedSketches[currentRefinedIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project3 prev"
                  onClick={prevRefined}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project3 next"
                  onClick={nextRefined}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="right-col">
            <div className="project-refinedSketch-title">
              Storyboard Sketches: ScreenWise Interactions
            </div>
            <p className="sketch-decription">
              ScreenWise features two distinct user interactions, both
              illustrated through storyboards in the carousel to the left. These
              storyboards demonstrate how ScreenWise addresses the everyday
              challenge in optimizing TV viewing experiences.
            </p>
            <p className="sketch-decription">
              The <strong>Optimal View Storyboard</strong> illustrates how a
              user can utilize ScreenWise to determine the ideal viewing
              distance from their TV. This interaction begins with the user
              positioning themselves in front of the TV and activating the
              device. Using its ultrasonic sensor, ScreenWise measures the
              distance between the user and the screen. The LCD display provides
              feedback, guiding the user to adjust their position for the
              perfect viewing distance. Once the user is at the optimal
              distance, an LED light illuminates, confirming that the setup
              ensures both comfort and an ideal viewing experience.
            </p>
            <p className="sketch-decription">
              The <strong>Perfect TV Storyboard</strong> demonstrates how
              ScreenWise assists users in selecting the right TV size for a
              predefined entertainment area. This interaction starts with the
              user setting up ScreenWise in the intended viewing location. Using
              the ultrasonic sensor, ScreenWise calculates the distance to the
              viewing area, and the user cycles through potential TV sizes. Once
              the correct TV size is selected, the LED light illuminates, and
              the optimal TV size is displayed on the LCD screen. This process
              eliminates guesswork and helps users make well-informed decisions
              when choosing a TV for their space.
            </p>
            <p className="sketch-decription">
              For a closer look at the Storyboards, please view the carousel on
              the left, which showcases the 2 unique interactions in detail.
            </p>
          </div>
        </div>

        {/* Project Final Product */}
        <div className="project-finalProduct-container">
          <div className="project-finalProduct-title">
            Project Reflection - ScreenWise
          </div>
          <div className="project-video-group-project3">
            {/* <video controls class="video-demo" src={videoDemo} /> */}
            <iframe
              className="video-demo-group-project3"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/WPp-GpRaM54"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p className="sketch-decription">
            Above is a video demonstration of ScreenWise in action.
          </p>
          <p className="sketch-decription">
            Breaking down our physical prototype, for inputs, we used buttons to
            toggle between states. For the sensor-based input, we utilized the
            Ultrasonic Sensor to track distances. For outputs, we employed an
            LCD display to show the set TV size and the distance, along with an
            LED light that toggled on when the optimal viewing range for a given
            TV size was achieved.
          </p>
          <p className="sketch-decription">
            Upon reviewing the components, the ultrasonic sensor performed well
            within a range of 4 meters. However, when the sensor faced
            non-smooth surfaces, sporadic readings occurred, likely due to how
            sound waves were reflected back—or in some cases, not reflected at
            all. The other components, including the LCD display, LED lights,
            buttons, and power supply module, functioned flawlessly throughout
            the project.
          </p>
          <p className="sketch-decription">
            One limitation we encountered was the small number of available
            ports on the Arduino, which restricted the number of inputs and
            outputs we could integrate into our prototype. This limitation
            hindered our ability to create a more innovative and feature-rich
            design. For future projects involving Arduinos, I would like to
            research and utilize components, such as multiplexer modules, that
            can expand the number of usable ports, enabling more complex and
            creative prototypes.
          </p>
          <p className="sketch-decription">
            In terms of the physical structure and appearance of our prototype,
            we faced challenges in creating a functional and aesthetically
            pleasing casing. One major issue was with our initial button
            mechanism: we used wooden dowels with adhesive putty to press the
            buttons inside the casing. However, the dowels frequently slipped,
            resulting in inconsistent button presses. To address this, we added
            straws around the dowels, which stabilized them and ensured
            consistent and smooth button operation.
          </p>
          <p className="sketch-decription">
            After viewing the live demos from other groups, I realized there
            were additional components and features we could have incorporated
            into our design to enhance its functionality and appeal. If I could
            redo this project, I would especially focus on using a 3D printer to
            create a custom casing for the prototype. A 3D-printed case would
            have provided a much cleaner, more professional look while
            maintaining functionality.
          </p>
        </div>

        <div className="project-contribution-container">
          <div className="left-col">
            <div className="project-contribution-title">Contribution:</div>
            <p className="sketch-decription">
              <ul className="contribution-list">
                <li className="contribution-bullet">Ardunio Logic - Desmond</li>
                <li className="contribution-bullet">
                  Assembling Physical Prototype - Alan, Desmond
                </li>
                <li className="contribution-bullet">
                  Physical Prototype Casing - Alan, Braden, and Desmond
                </li>
                <li className="contribution-bullet">Video - Braden</li>
              </ul>
            </p>
          </div>
          <div className="right-col">
            <div className="project-contribution-title">
              Source Code & References:
            </div>
            <p className="sketch-decription">
              <ul className="contribution-list references">
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="https://github.com/dkhlau725/cpsc-581-arduino"
                  >
                    Source Code
                  </a>
                </li>
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="/human-computer-interactions"
                  >
                    HCI Portfolio
                  </a>
                </li>
                <li className="contribution-bullet">
                  Refernces:
                  <ul>
                    <li>
                      Portfolio Background - Generated by ChatGPT's Dall-E
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* <div className="section-footer-project">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PhysicalPrototype;
