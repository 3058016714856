import React, { useState } from "react";
import { useEffect } from "react";

// CSS
import "../components/underConstruction.css";

import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import videoDemo from "../assets/hci/groupProject2/video/pathZVideo.mp4";
import projectTwoImg from "../assets/hci/groupProject2/img/mazeZ.png";

// Initial Sketches:
import gyroMazeUnlock from "../assets/hci/groupProject2/initialSketches/gryoMazeUnlock.png";
import createAShapeInTheAir from "../assets/hci/groupProject2/initialSketches/createAShapeInTheAir.png";
import orientation from "../assets/hci/groupProject2/initialSketches/orientation.png";
import placeInBasket from "../assets/hci/groupProject2/initialSketches/placeInBasket.png";
import picklock from "../assets/hci/groupProject2/initialSketches/picklock.png";
import adjustTheMaze from "../assets/hci/groupProject2/initialSketches/adjustTheMaze.png";
import combinationLock from "../assets/hci/groupProject2/initialSketches/combinationLock.png";
import pendulumSwing from "../assets/hci/groupProject2/initialSketches/pendulumSwing.png";
import rotateTheStars from "../assets/hci/groupProject2/initialSketches/rotateTheStars.png";
import pourWater from "../assets/hci/groupProject2/initialSketches/pourWater.png";

// Refined Sketches:
import mazeRefinement from "../assets/hci/groupProject2/refinedSketches/mazeRefinement.png";
import circularMazeRefinement from "../assets/hci/groupProject2/refinedSketches/circularMazeRefinement.png";
import finalRefinement from "../assets/hci/groupProject2/refinedSketches/finalRefinement.jpg";

const PhoneSensorUnlock = () => {
  // Modify Background to be unique to HCI Project Pages
  useEffect(() => {
    document.body.className = "wrapper";
    return () => {
      document.body.className = ""; // Reset on unmount
    };
  }, []);

  // State for enlarged image
  const [enlargedImage, setEnlargedImage] = useState(null);

  // Array of images with captions
  const initialSketches = [
    {
      src: gyroMazeUnlock,
      alt: "Gyro Maze Unlock Feature",
      caption:
        "|Figure 1: Maze + Gyroscope Sensors| This concept sketch envisions unlocking the phone by navigating a maze to reach the correct exit. Movement is controlled by gyroscope sensors, and multiple exits are available; the user must choose the correct one to unlock the phone.",
    },
    {
      src: createAShapeInTheAir,
      alt: "Create A Shape In The Air Unlock Feature",
      caption:
        "|Figure 2: Accelerometer + Gyroscope Sensor Tracking| In this sketch, the phone is unlocked by tracking the phone’s motion in two dimensions as the user gestures a specific shape using both accelerometer and gyroscope sensors. If the completed shape matches the correct pattern, the phone unlocks.",
    },
    {
      src: orientation,
      alt: "Orientation Combination Unlock Feature",
      caption:
        "|Figure 3: Track Orientation Sensor Combination| This sketch proposes unlocking the phone through a sequence of specific phone orientations. The user taps the screen and then places the phone in a specified orientation for a set duration, repeating this process three more times. If the orientation sequence and timing are correct, the phone unlocks.",
    },
    {
      src: placeInBasket,
      alt: "Place The Ball In The Basket Unlock Feature",
      caption:
        "|Figure 4: Control A Falling Ball With Gyroscope Sensor Sensor Tracking| This concept involves unlocking the phone by guiding a falling ball into the correct basket. The user taps the screen to release the ball, then uses gyroscope-controlled motions to steer it into the right basket. This is repeated four times, creating a combination of baskets. If the basket sequence is correct, the phone unlocks.",
    },
    {
      src: picklock,
      alt: "Picklock Unlock Feature",
      caption:
        "|Figure 5: Gyroscope Sensor + Picklock Logic| This sketch envisions unlocking the phone by simulating a lock-picking process. The user taps the screen to insert a virtual pick into the lock and then uses gyroscope motions to lift each pin to the correct height. Once the pick reaches the end of the lock, the pin depths are checked. If the combination is correct, the phone unlocks.",
    },
    {
      src: adjustTheMaze,
      alt: "Adjust The Maze Unlock Feature",
      caption:
        "|Figure 6: Touch + Gyroscope Sensors| In this sketch, the phone is unlocked by adjusting layered circular mazes using touch and gyroscope sensors. The user taps the screen to rotate the mazes, locking the orientation with another tap. They then navigate to the correct exit using gyroscope controls to unlock the phone.",
    },
    {
      src: combinationLock,
      alt: "Combination Lock Unlock Feature",
      caption:
        "|Figure 7: Combination Lock + Gyroscope Sensors| This concept sketch unlocks the phone through a combination lock controlled by gyroscope sensors. After tapping the screen to activate the lock, the user rotates the phone to enter the combination. If successful, the phone unlocks.",
    },
    {
      src: pendulumSwing,
      alt: "Pendulum Swing Unlock Feature",
      caption:
        "|Figure 8: Pendulum Swing + Gryoscope Sensors| This concept involves using a pendulum to unlock the phone by swinging it to hit a series of switches. The user taps the screen to release the pendulum, then uses gyroscope controls to hit randomly scrolling switches on either side of the screen. After four successful hits, the switch combination is checked; if correct, the phone unlocks.",
    },
    {
      src: rotateTheStars,
      alt: "Rotate The Stars Unlock Feature",
      caption:
        "|Figure 9: Constellations + Gryoscope Sensors| In this concept, the user unlocks the phone by aligning it to match a specific constellation pattern. After tapping the screen, the user rotates the phone using gyroscope sensors to adjust the constellation’s position. Once the correct pattern is locked in, the phone unlocks.",
    },
    {
      src: pourWater,
      alt: "Pour Water Unlock Feature",
      caption:
        "|Figure 10: Pouring Water + Gyroscope Sensor| This concept sketch involves unlocking the phone by tilting it to 'pour' water from a virtual glass on the screen. The user must tilt the phone back upright, leaving a precise amount of water in the glass. If the remaining water is correct, the phone unlocks.",
    },
  ];

  const refinedSketches = [
    {
      src: mazeRefinement,
      alt: "Maze Refinement Unlock Feature",
      caption:
        "|Figure: Maze Refinement| This refinement sketch was based off of the gryo maze concept sketch. To build on the initial idea and to add creativity and complexity to the original sketch, the idea was to track additional variables within the maze. So the condition to unlock the phone is to pass the correct set of letter variables in the right order and then the right exit among many exits.",
    },
    {
      src: circularMazeRefinement,
      alt: "Circular Maze Refinement Unlock Feature",
      caption:
        "|Figure: Circular Maze Refinement| This refinement was based off of the adjustable maze concept sketch. To build on the initial idea and to add creativity and complexity to the original sketch. I removed the  touch sensor and added gryoscope sensors to rotate the maze. The user would tap the screen and there will be two layers of circular mazes. The user would rotate the phone along the x-axis and y-axis to adjust the maze to the desired layout then touch the screen to lock in layout. Lastly, the user would have to exit the maze from the correct exit to properly unlock the phone.",
    },
    {
      src: finalRefinement,
      alt: "Final Refinement Unlock Feature",
      caption:
        "|Figure: Final Refinement| This refinement was created by groupmate Rezwan Ahmed and used to build our final product. The idea for this sketch is to navigate through a letter maze to unlock your phone. To add complexity, the user has to reach both exits in the correct order. If the user follows the proper order they unlock the next maze. The process continues until all maze letters are complete and the phone is unlocked.",
    },
  ];

  // State for initial sketches carousel
  const [currentInitialIndex, setCurrentInitialIndex] = useState(0);

  // State for refined sketches carousel
  const [currentRefinedIndex, setCurrentRefinedIndex] = useState(0);

  const nextInitial = () => {
    setCurrentInitialIndex((currentInitialIndex + 1) % initialSketches.length);
  };

  const prevInitial = () => {
    setCurrentInitialIndex(
      (currentInitialIndex - 1 + initialSketches.length) %
        initialSketches.length
    );
  };

  const nextRefined = () => {
    setCurrentRefinedIndex((currentRefinedIndex + 1) % refinedSketches.length);
  };

  const prevRefined = () => {
    setCurrentRefinedIndex(
      (currentRefinedIndex - 1 + refinedSketches.length) %
        refinedSketches.length
    );
  };

  return (
    <div className="container">
      {/* Enlarge Image Overlay */}
      {enlargedImage && (
        <div
          className="enlarged-image-overlay"
          onClick={() => setEnlargedImage(null)}
        >
          <img src={enlargedImage} alt="Enlarged" className="enlarged-image" />
        </div>
      )}

      <div className="section-header hci-group-project2">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-center">Phone Sensor Unlock - Z Maze</div>
          <div className="header-links">
            <a
              className="header-nav-option"
              href="/human-computer-interactions"
            >
              HCI Projects
            </a>
          </div>
        </div>
      </div>

      <div className="section-project group-project2">
        {/* Project Hero */}
        <div className="project-hero">
          <div className="project-header">
            <div className="project-title">Z Maze</div>
            {/* <div className="project-subtitle">Group Project 1</div> */}
            <div className="groupmates">
              Alan Huynh, Erich Heng, Rezwan Ahmed
            </div>
          </div>
          <div className="project-hero-img-container">
            <img
              className="project-hero-img"
              src={projectTwoImg}
              alt={"Z Maze"}
            />
          </div>
          <div className="project-overview">
            <div className="project-overview-title">
              Project Overview: "Group Project 2 - Phone Sensor Unlock"
            </div>
            <p className="sketch-decription">
              The goal of this project was to design and implement an
              innovative, sensor-based mobile unlock interface as an alternative
              to standard methods on the market today. The interface needs to
              have a unique interaction using the mobile sensors, such as the
              accelerometer, gyroscope, or light sensor, to provide a secure way
              to access the phone.
            </p>
            <p className="sketch-decription">
              Our group project, titled <i>Z Maze</i>, was designed around
              mobile orientation sensors that provide real-time feedback. Our
              project allows users to see when actions are recognized,
              indicating both successful unlock attempts and failed unlock
              attempts. We ensured to minimize the risk of accidental unlocks,
              ensuring only deliberate user actions grant access to the device.
            </p>
            <p className="sketch-description">
              This portfolio will document the design process, initial sketches,
              refined sketches, final design, and final demonstrations.
            </p>
          </div>
        </div>

        {/* Project Initial Sktches */}
        <div className="project-initialSketch-container">
          <div className="left-col">
            <div className="project-initialSketch-title">
              Initial Sketches: Design Process for Z Maze
            </div>
            <p className="sketch-decription">
              After exploring the different types of phone sensors, our group
              decided to brainstorm ideas on gyroscope and accelerometer
              sensors. We believed these sensors would be the most practical for
              programming a phone unlocking application. Each group member was
              assigned to create 10 initial sketches of potential phone
              sensor-based unlocking applications. Although we weren’t limited
              to using the gyroscope, I leaned heavily toward concepts utilizing
              this sensor.
            </p>
            <p className="sketch-decription">
              In my 10 initial sketches, I primarily centered my ideas around
              gyroscope-based unlocking mechanisms. Many of my concepts were
              inspired by games, such as maze games (Sketch 1), Doodle Jump
              (Sketch 4), and Tetris (Sketch 8).
            </p>
            <p className="sketch-decription">
              Of the 10 initial sketches, the lock-picking concept (Sketch 5)
              intrigued me the most as a candidate for refinement and final
              development. This concept involves using motion sensors to press
              down pins within a virtual lock, pressing the correct combination
              would unlock the phone. I thought this approach was both creative
              and capable of generating diverse combinations, making brute-force
              unlocking attempts more challenging. Ultimately, as a group, we
              decided to pursue a different direction.
            </p>
            <p className="sketch-decription">
              For more details about the sketches, please view the carousel on
              the right, which showcases all 10 initial designs.
            </p>
          </div>
          <div className="right-col">
            <div className="carousel">
              <img
                className="initial-sketch"
                src={initialSketches[currentInitialIndex].src}
                alt={initialSketches[currentInitialIndex].alt}
                onClick={() =>
                  setEnlargedImage(initialSketches[currentInitialIndex].src)
                }
              />
              <p className="caption">
                {initialSketches[currentInitialIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project2 prev"
                  onClick={prevInitial}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project2 next"
                  onClick={nextInitial}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Project Refine Sketch */}
        <div className="project-refinedSketch-container">
          <div className="left-col">
            <div className="carousel">
              <img
                className="refined-sketch"
                src={refinedSketches[currentRefinedIndex].src}
                alt={refinedSketches[currentRefinedIndex].alt}
                onClick={() =>
                  setEnlargedImage(refinedSketches[currentRefinedIndex].src)
                }
              />
              <p className="caption">
                {refinedSketches[currentRefinedIndex].caption}
              </p>
              <div className="carousel-buttons">
                <button
                  className="carousel-button hci-group-project2 prev"
                  onClick={prevRefined}
                >
                  Previous
                </button>
                <button
                  className="carousel-button hci-group-project2 next"
                  onClick={nextRefined}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="right-col">
            <div className="project-refinedSketch-title">
              Refined Sketches: Z Maze's Development
            </div>
            <p className="sketch-decription">
              After each group member created 10 initial sketches, we reviewed
              all the designs together and selected a few ideas that we believed
              were the most creative and practical for further refinement. Our
              decision was based on the uniqueness of the concept and how
              feasible it would be to implement these ideas in an actual
              application.
            </p>
            <p className="sketch-decription">
              Among the collection of sketches, we noticed a common interest in
              maze related concepts and gryoscope sensors. As a result, we
              decided to create 10 refined sketches focused on two main ideas:
              maze related concepts and concepts focused around gryoscope
              sensors.
            </p>
            <p className="sketch-decription">
              For my refined sketches, I focused on expanding the idea of using
              gyroscope sensors to navigate an object (primarily a ball) through
              different combination of obstacles to unlock the phone. The two
              refinements that are shown in this portfolio revolve around mazes.
              One refinement was to add complexity to the classic maze structure
              by adding variables to collect in a specific order before exiting
              the maze. The idea is similar to Pacman but the combination of
              variables passed matters to unlock the phone. The second
              refinement was to add complexity to the maze structure by allowing
              the user to use gyroscope sensors to alter the maze structure
              before navigating to a specific exit.
            </p>
            <p className="sketch-decription">
              For more details about the refined sketches, please view the
              carousel on the left, which showcases two main designs along with
              the refinement by Ahmed that was the inspiration for our project.
            </p>
          </div>
        </div>

        {/* Project Final Product */}
        <div className="project-finalProduct-container">
          <div className="project-finalProduct-title">
            Final Design: Z Maze Application
          </div>
          <div className="project-video">
            <video controls class="video-demo" src={videoDemo} />
          </div>
          <p className="sketch-decription">
            In the final step of our project, the group reviewed all the refined
            sketches and collectively decided on the design for the application.
            The application was built on Rezwan's sketch of letter mazes. The
            final design featured mazes resembling the shapes 'Z' and 'W', each
            maze has two exit points labelled by a specific letter, and a ball
            in the maze to show action. In addition, there is a 'life count'
            feature to reduce brute force attempts and avoid accidental
            unlocking attempts.
          </p>
          <p className="sketch-decription">
            To navigate through the maze we originally wanted to use gyroscope
            sensors but the practicality of implementing gryoscope sensor to
            handle movement was more difficult than using orientation sensors.
            So for the application development, orientation sensors on the beta
            and gamma axis were used to control the movement of the ball through
            the maze.
          </p>
          <p className="sketch-decription">
            The goal for the project was for the user to navigate the ball to
            the exits in a specific order. To limit brute force attempts, the
            user was only informed of the correctness of their choice after a
            set of two exits were reached. If wrong, they lose a life.
            Therefore, the user has to properly navigate through the exits of
            all mazes to unlock the phone.
          </p>
          <div className="project-demo-link">
            <button className="project-btn">
              <a
                className="project-link"
                href="https://dashing-dent-bladder.glitch.me/"
              >
                Z Maze Demo
              </a>
            </button>
          </div>
        </div>

        <div className="project-contribution-container">
          <div className="left-col">
            <div className="project-contribution-title">Contribution:</div>
            <p className="sketch-decription">
              <ul className="contribution-list">
                <li className="contribution-bullet">
                  Application Development - Alan
                </li>
                <li className="contribution-bullet">
                  Original Concept & Video Demonstration - Rezwan
                </li>
                <li className="contribution-bullet">
                  Presentation & Project Submission - Erich
                </li>
              </ul>
            </p>
          </div>
          <div className="right-col">
            <div className="project-contribution-title">
              Source Code & References:
            </div>
            <p className="sketch-decription">
              <ul className="contribution-list references">
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="https://glitch.com/edit/#!/dashing-dent-bladder"
                  >
                    Source Code
                  </a>
                </li>
                <li className="contribution-bullet">
                  Refernces:
                  <ul>
                    <li>IOS Permission Request Logic: ChatGPT</li>
                    <li>
                      Phone Sensor Logic:{" "}
                      <a
                        className="anchor-hci-group-project3"
                        href="https://glitch.com/edit/#!/cpsc-581-sensor-solution"
                      >
                        {" "}
                        Jane Shen's Tutorial Code
                      </a>
                    </li>
                    <li>
                      Endpoint Letter Images: Generated on Canvas by Ahmed
                    </li>
                  </ul>
                </li>
                <li className="contribution-bullet">
                  <a
                    className="anchor-hci-group-project3"
                    href="/human-computer-interactions"
                  >
                    HCI Portfolio
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* <div className="section-footer-project">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PhoneSensorUnlock;
