import React from "react";

// import "../assets/hci/groupProject1/styles/style.css";

import fatSuperBuffSelfie from "../assets/hci/groupProject1/images/Cat_FatSuperBuff.webp";
import superBuffSelfie from "../assets/hci/groupProject1/images/Cat_SuperBuff.webp";
import fatBuffSelfie from "../assets/hci/groupProject1/images/Cat_FatBuff.webp";
import buffSelfie from "../assets/hci/groupProject1/images/Cat_Buff.webp";
import fatAverageSelfie from "../assets/hci/groupProject1/images/Cat_FatAverage.webp";
import averageSelfie from "../assets/hci/groupProject1/images/Cat_Average.webp";
import fatScrawnySelfie from "../assets/hci/groupProject1/images/Cat_FatScrawny.webp";
import leanScrawnySelfie from "../assets/hci/groupProject1/images/Cat_LeanScrawny.webp";
import scrawnySelfie from "../assets/hci/groupProject1/images/Cat_Scrawny.webp";
import food from "../assets/hci/groupProject1/images/Sushi.webp";

const Buffify = () => {
  return (
    <div>
      <header class="header">
        <div>
          <p>CPSC 581 - Group Project</p>
        </div>
      </header>
      <main>
        <div class="container">
          <div></div>
          {/* <Selfie Box Container */}
          <div class="selfie-container">
            <p class="container-title">Buffify</p>
            <div></div>
            <img
              class="selfie-img hidden"
              alt="FatSuperBuffSelfie"
              src="/images/Cat_FatSuperBuff.webp"
            />
            <img
              class="selfie-img hidden"
              alt="SuperBuffSelfie"
              src="/images/Cat_SuperBuff.webp"
            />
            <img
              class="selfie-img hidden"
              alt="FatBuffSelfie"
              src="/images/Cat_FatBuff.webp"
            />
            <img
              class="selfie-img hidden"
              alt="BuffSelfie"
              src="/images/Cat_Buff.webp"
            />
            <img
              class="selfie-img"
              alt="FatAverageSelfie"
              src="/images/Cat_FatAverage.webp"
            />
            <img
              class="selfie-img"
              alt="AverageSelfie"
              src="/images/Cat_Average.webp"
            />
            <img
              class="selfie-img hidden"
              alt="FatScrawnySelfie"
              src="/images/Cat_FatScrawny.webp"
            />
            <img
              class="selfie-img hidden"
              alt="LeanScrawnySelfie"
              src="/images/Cat_LeanScrawny.webp"
            />
            <img
              class="selfie-img hidden"
              alt="ScrawnySelfie"
              src="/images/Cat_Scrawny.webp"
            />
          </div>

          {/* <!-- Avatar Container--> */}
          <div class="avatar-container">
            <img
              id="center-img"
              class="avatar default"
              alt="avatar"
              src="/images/avatar.webp"
            />
          </div>

          {/* <!-- Two Button Container--> */}
          <div class="two-btn-container">
            <div></div>
            <div>
              <div class="cross-container">
                {/* <!-- Cross Path --> */}
                <div class="l-path"></div>
                {/* <!-- Draggable Button --> */}
                <div id="draggableButton" class="joystick-btn button"></div>
              </div>
            </div>

            <div></div>

            <button class="food-btn">
              <img class="food-img" alt="food-btn" src="/images/Sushi.webp" />
            </button>

            <div></div>
          </div>
        </div>
      </main>
      <footer class="footer">
        <div>
          <ul class="page-links">
            <li>
              <a href="https://alanhuynh.ca/a-Tale-Of-Two-Buttons">
                Alan's Portfolio
              </a>
            </li>
            <li>
              <a href="https://kevinhoang9268.wixsite.com/581a1portfolio">
                Kevin's Portfolio
              </a>
            </li>
            <li>
              <a href="https://cpsc581n4h33n.wordpress.com/2024/09/30/group-project-1-buffify/">
                Naheen's Portfolio
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Buffify;
