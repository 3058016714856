import React from "react";
import "../components/underConstruction.css";

import logoImg from "../assets/home/img/icons/Logo/png/logo-no-background.png";
import projectOneImg from "../assets/hci/groupProject1/img/avatar.webp";
import projectTwoImg from "../assets/hci/groupProject2/img/mazeZ.png";
import projectThreeImg from "../assets/hci/indivProject/img/MirrorBackground.webp";
import projectFourImg from "../assets/hci/groupProject3/img/screenWise.webp";

const Cpsc581 = () => {
  return (
    <div className="container">
      <div className="section-header hci">
        <div className="header-container">
          <a className="header-logo-anchor" href="/">
            <img
              className="header-logo-img"
              src={logoImg}
              alt={"Portfolio Logo"}
            />
          </a>
          <div className="header-center">
            Human Computer Interactions II - Projects
          </div>
          {/* <div className="header-links">
            <a className="header-nav-option" href="*">
              More Projects
            </a>
          </div> */}
        </div>
      </div>

      <div className="section-main">
        <div className="main-container">
          {/* Project 1 */}
          <div className="main-message">
            <h1 className="message-title">A Tale of Two Buttons</h1>
            <p className="message-subtitle">Group Project 1 - Buffify</p>
            <img
              className="message-img"
              src={projectOneImg}
              alt={"Cat Pixel Art"}
            />
            <p className="message-description">
              Creatiing an application that tells the personality of a
              groupmate.
            </p>
            <button className="project-btn">
              <a className="project-link" href="/a-Tale-Of-Two-Buttons">
                Group Project 1
              </a>
            </button>
          </div>

          {/* Project 2 */}
          <div className="main-message">
            <h1 className="message-title">Phone Sensor Unlock</h1>
            <p className="message-subtitle">Group Project 2 - Path Z</p>
            <img
              className="message-img project2img"
              src={projectTwoImg}
              alt={"Z Maze"}
            />
            <p className="message-description">
              Using phone sensors to create a unique phone unlock.
            </p>
            <button className="project-btn">
              <a className="project-link" href="/phone-sensor-unlock">
                Group Project 2
              </a>
            </button>
          </div>

          {/* Project 3 */}
          <div className="main-message">
            <h1 className="message-title">Proxemics</h1>
            <p className="message-subtitle">
              Individual Project - Bathroom Mirror Proxemics
            </p>
            <img
              className="message-img project2img"
              src={projectThreeImg}
              alt={"Bathroom Wall"}
            />
            <p className="message-description">
              Creating a smart home object that uses proxemics.
            </p>
            <button className="project-btn">
              <a className="project-link" href="/proxemics">
                Individual Project
              </a>
            </button>
          </div>

          {/* Project 4 */}
          <div className="main-message">
            <h1 className="message-title">Physical Prototype</h1>
            <p className="message-subtitle">Group Project 3 - ScreenWise</p>
            <img
              className="message-img project2img"
              src={projectFourImg}
              alt={"Bathroom Wall"}
            />
            <p className="message-description">
              Creating a physical prototype to solve an everyday problem.
            </p>
            <button className="project-btn">
              <a className="project-link" href="/physical-prototype">
                Group Project 3
              </a>
            </button>
          </div>
        </div>
      </div>

      {/* <div className="section-footer">
        <div className="footer-container">
          <div className="footer-credit">
            <p>Background generated by DALL-E</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Cpsc581;
